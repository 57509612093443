/* 
 * @Author: 曹俊杰 
 * @Date: 2020-09-28 16:35:40
 * @Module: 直播监控 box
 */
 <template>
  <div class="box">
    <div class="left">
      <flvvideo :config="config"
                @player="Player = $event"
                :url="url"
                :itemkey="itemkey" />
    </div>
    <div class="right">
      <div class="top">
        <div>{{itemdata.tick_name}}</div>
        <div>探探号：{{itemdata.tick_account}}</div>
      </div>
      <div class="bottom">
        <div>
          <div>{{itemdata.member_count}}</div>
          <div>观看人数</div>
        </div>
        <div>
          <div>{{itemdata.flow/10000}}</div>
          <div>本场流水/万</div>
        </div>
        <div>
          <div>{{duration}}</div>
          <div>开播时长/分</div>
        </div>
      </div>
    </div>
  </div>
</template>
 <script>
import flvvideo from './flvvideo';
let Interval = null
export default {
  props: {
    url: {
      type: String,
      default: ''
    },
    itemkey: {
      type: String,
      default: ''
    },
    itemdata: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    flvvideo
  },
  data () {
    return {
      config: {
        autoplay: true,
        volume: 0,
        fluid: true,
        width: 250,
        height: 445
      },
      Player: null,
      nowdate: Date.parse(new Date())
    };
  },
  mounted () {
    console.log(this.url)
    Interval = setInterval(() => {
      this.nowdate = Date.parse(new Date())
    }, 60000);
  },
  methods: {},
  computed: {
    duration () {
      return parseInt((this.nowdate - this.$dateFormat(this.itemdata.broadcasting_start_time || '')) / 60000)
    }
  },
  beforeDestroy () {
    clearInterval(Interval)
  }
};
 </script>
 <style scoped lang="scss">
.box {
  width: 500px;
  height: 445px;
  background: #ffffff;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  .left,
  .right {
    width: 250px;
    height: 445px;
  }
  .right {
    .top {
      margin-top: 30px;
      width: 250px;
      height: 64px;
      padding: 0 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 18px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      :first-child {
        font-weight: 700;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
      }
      :last-child {
        margin-top: 12px;
        font-size: 14px;
        color: #666;
      }
    }
    .bottom {
      width: 225px;
      height: 68px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      background: #f7f7f7;
      margin: 0 auto;
      margin-top: 19px;
      div {
        text-align: center;
        color: #333;
        font-size: 15px;
      }
      div :last-child {
        margin-top: 9px;
        color: #999;
        font-size: 10px;
      }
    }
  }
}
</style>