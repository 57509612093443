/* 
 * @Author: 曹俊杰 
 * @Date: 2020-09-28 15:28:32
 * @Module: 直播监控
 */
 <template>
  <div>
    <div style="line-height:58px;color:#333;font-size:20px;font-weight:700;">
      直播监控
    </div>
    <el-card>
      <el-row :gutter="20"
              style="background:#fff;">
        <el-form inline
                 :size="'small'"
                 :model="params"
                 class="demo-form-inline">
          <el-col :span="6">
            <el-form-item label="探探号:"
                          style="margin:0px;">
              <el-input v-model="params.tick_account"
                        clearable
                        placeholder="请输入要查询的探探号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="经纪人:"
                          style="margin:0px;">
              <brokerSelect @selectTreeChange="broker_id=>params.broker_id=broker_id"
                            @clearBrokerSelect="()=>params.broker_id = null"
                            placeholder="请选择经纪人"></brokerSelect>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-button type="primary"
                       icon="el-icon-search"
                       @click="req_list(true)">搜索</el-button>
          </el-col>
        </el-form>
      </el-row>
    </el-card>
    <div v-loading="loading"
         style="min-height:500px;margin-top:20px;">
      <div class="monitor-box"
           v-if="!loading">
        <anchorMonitorItem v-for="(item,index) in list"
                           :key="index"
                           :url="item.stream_url"
                           :itemkey="item.anchor_id+''"
                           :itemdata="item"
                           style="margin-bottom:20px;margin-right:20px;" />
      </div>
      <el-pagination @current-change="handleCurrentChange"
                     :current-page="currentPage"
                     :page-size="6"
                     :hide-on-single-page="list.length ==0"
                     layout="total, prev, pager, next"
                     :total="total" />

    </div>
  </div>
</template>
<script>
import anchorMonitorItem from './components/anchorMonitorItem';
import brokerSelect from 'components/brokerSelect'
export default {
  components: {
    anchorMonitorItem,
    brokerSelect
  },
  data () {
    return {
      url: null,
      len: 0,
      total: 0,
      currentPage: 1,
      loading: true,
      list: [],
      params: {
        page: 1,
        tick_account: '',
        broker_id: null
      },
    };
  },
  created () {
    this.req_list(true)
  },
  methods: {
    handleCurrentChange (page) {
      this.params.page = page
      this.req_list()
    },
    async _initState (params = {}) {
      this.loading = true
      const {
        status_code,
        meta: {
          pagination: { total },
        },
        message,
      } = await this.$api.anchorMonitorList(params)
      if (status_code === 200) {
        this.total = total
        this.list = message
        this.loading = false
      }
    },
    // 请求列表
    req_list (refresh) {
      this.loading = true
      if (refresh) this.params.page = 1
      this._initState(this.params)
    },
  },
  computed: {
  }
};
 </script>
 <style scoped lang="scss">
.monitor-box {
  display: flex;
  flex-wrap: wrap;
  max-width: 1560px;
}
</style>